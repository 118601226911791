import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import theme from "../utils/theme";
import {
  laptopToDesktopQuery,
  mobileToTabletQuery,
} from "../utils/mediaqueries";
import Seo from "../components/Seo";

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
});

const StyledFour = styled.h1({
  color: theme.colors.orangeBackground,
  margin: 0,
  padding: 0,

  [mobileToTabletQuery]: {
    fontSize: "5rem",
  },

  [laptopToDesktopQuery]: {
    fontSize: "9rem",
  },
});

const StyledNotFound = styled.h2({
  margin: 0,
  padding: 0,
  color: "white",

  [mobileToTabletQuery]: {
    fontSize: "2rem",
  },

  [laptopToDesktopQuery]: {
    fontSize: "3rem",
  },
});

const StyledHome = styled(Link)({
  marginTop: "20px",
  color: theme.colors.lightOrangeBackground,

  [mobileToTabletQuery]: {
    fontSize: "1.5rem",
  },

  [laptopToDesktopQuery]: {
    fontSize: "3rem",
  },
});

const NotFoundPage = () => (
  <Wrapper>
    <Seo title="404 - Stránka se nenašla" />
    <StyledFour>404</StyledFour>
    <StyledNotFound>
      Sry, tuhle stránku nemůžeme najít{" "}
      <span role="img" aria-label="smutný smajlík">
        😕
      </span>
    </StyledNotFound>
    <StyledHome to="/">Zpět na homepage</StyledHome>
  </Wrapper>
);

export default NotFoundPage;
